import "./Footer.css"
import {FaHome, FaPhone, FaMailBulk, FaFacebook, FaTwitter, FaLinkedin} from "react-icons/fa"


// import React from 'react'
// import Github from "../../assets/github.png"
// import Instagram from "../../assets/instagram.png"
// import Linkedin from "../../assets/linkedin.png"
// import Logo from "../../assets/logo.png"

const Footer = () => {
  return (
    <div className="footer">
        <hr></hr>
        <div className="footer-container">
        <div className="blur blur-f-1"></div>
            <div className="left">
                <div className="location">
                    <FaHome size={20} style={{color: "var(--orange)", marginRight: "2rem"}}/>
                    <div>
                        <p>Mardan, KPK.</p>
                        <p>Pakistan.</p>
                    </div>
                </div>
                <div className="phone">
                    <h4><FaPhone size={20} style={{color: "var(--orange)", marginRight: "2rem"}}/>
                +92 316 8098351</h4>
                </div>
                <div className="email">
                    <h4><FaMailBulk size={20} style={{color: "var(--orange)", marginRight: "2rem"}}/>
                saify1369@gmail.com1</h4>
                </div>
            </div>
            <div className="right">
                <h4>About GYM</h4>
                <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries.</p>
                <div className="social">
                <FaFacebook size={30} style={{color: "var(--orange)", marginRight: "2rem"}}/>
                <FaTwitter size={30} style={{color: "var(--orange)", marginRight: "2rem"}}/>
                <FaLinkedin size={30} style={{color: "var(--orange)", marginRight: "2rem"}}/>
                </div>
            </div>
        </div>
        <div className="blur blur-f-2"></div>
    </div>
  )
}

export default Footer