import "./Join.css"
import React, { useRef } from 'react'
// import emailjs from "@emailjs/browser"

const Join = () => {
    const form = useRef()

    // const sendEmail = (e) => {
    //     e.preventDefault();
    
    //     emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_PUBLIC_KEY')
    //       .then((result) => {
    //           console.log(result.text);
    //       }, (error) => {
    //           console.log(error.text);
    //       });
    //   };

  return (
    <div className="Join" id="join-us">
        <div className="left-j">
            <hr></hr>
            <div>
                <span className="stroke-text">READY TO</span>
                <span> LEVEL UP</span>
            </div>
            <div>
                <span>YOUR BODY </span>
                <span className="stroke-text">WITH US?</span>
            </div>
        </div>
        <div className="right-j">
            {/* insted of action use ref={form} for email service also add onSubmit={sendEmail}*/}
            <form action="" className="email-container" >
                <input type="email" name="user-email" placeholder="Enter your Email Address"></input>
                <button className="btn btn-j">Join Now</button>
            </form>
        </div>
    </div>
  )
}

export default Join