import React from 'react'
import Header from '../header/Header'
import "./Hero.css"

import hero_image from "../../assets/hero_image.png"
import hero_image_back from "../../assets/hero_image_back.png"
import heart from "../../assets/heart.png"
import calories from "../../assets/calories.png"
import {motion} from "framer-motion"
import NumberCounter from "number-counter"


const Hero = () => {

    const transition = {type: "spring", duration: 3}
    const mobile = window.innerWidth<=768 ?true:false;


  return (
    <div className='hero' id='home'>
        <div className='blur hero-blur'></div>
        <div className='left-h'>
            <Header />
            {/* the best ad */}
            <div className='the-best-ad'>
                <motion.div initial = {{left: mobile ? "165px":"238px"}}
                    whileInView={{left: "8px"}}
                    transition={{...transition, type: "tween"}}>
                </motion.div>
                <span>The best fintness club in pakistan</span>
            </div>


            {/* hero heading */}
            <div className='hero-text'>
                <div>
                    <span className='stroke-text'>Shape</span>
                    <span> Your</span>
                </div>
                <div>
                    <span>Ideal body</span>
                </div>
                <div>
                    <span>
                        In here we will help you to shape and build your ideal body and live up your life to fullest
                    </span>
                </div>
            </div>


            {/* figuers */}

            <div className='figuers'>
                <div>
                    <span>
                        <NumberCounter end={140} start={100} delay="4" preFix="+"></NumberCounter>
                    </span>
                    <span>EXPERT COACHES</span>
                </div>
                <div>
                    <span>
                    <NumberCounter end={987} start={847} delay="4" preFix="+"></NumberCounter>
                    </span>
                    <span>MEMBERS JOINED</span>
                </div>
                <div>
                    <span>
                    <NumberCounter end={50} start={35} delay="4" preFix="+"></NumberCounter>
                    </span>
                    <span>FITNESS PROGRAMS</span>
                </div>
            </div>


            {/* hero buttons */}

            <div className='hero-buttons'>
                <buttons className="btn">Get Started</buttons>
                <buttons className="btn">Learn More</buttons>
            </div>
        </div>


        {/* right side */}
        <div className='right-h'>
            <button className='btn'>Join Now</button>
            <motion.div 
            initial={{right: "-1rem"}}
            whileInView={{right: "4rem"}}
                transition={transition}
                className='heart-rate'>
                <img src={heart} alt="heart rate"></img>
                <span>Heart Rate</span>
                <span>116 bpm</span>
            </motion.div>

                 {/* hero image */}
            <img src={hero_image} alt="hero img 1" className='hero-image'></img>
            <motion.img 
            initial={{right: "11rem"}}
            whileInView={{right: "20rem"}}
            transition={transition}
            src={hero_image_back} alt="hero img 1" className='hero-image-back'></motion.img>

            {/* calories */}
            <motion.div 
                initial={{right: "37rem"}}
                whileInView={{right: "28rem"}}
                transition={transition}
                className='calories'>
                <img src={calories} alt="cal img"></img>
                <div>
                    <span>Calories Burned</span>
                    <span>220 Kcal</span>
                </div>
            </motion.div>
        </div>   
    </div>
  )
}

export default Hero